import {store} from "react-notifications-component";

const sendMessages = (props) => {
    const { title, message, type } = props;

    store.addNotification({
        title: title || 'Системное уведомление',
        message: message,
        type: type || 'info',
        insert: 'top',
        container: 'bottom-left',
        dismiss: {
            duration: 3000,
            onScreen: false,
            pauseOnHover: true,
            delay: 0
        }
    });
}

export {
    sendMessages
}
