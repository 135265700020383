import palette from '../palette';

export default {
  root: {
    minWidth: 'initial!important',
    padding: '10px 0',
    marginLeft: 32,

    textTransform: 'none',
    fontSize: 16,
    lineHeight: '16px',
    color: palette.black,

    '&$textColorInherit': {
      fontWeight: 'normal'
    },
    '&$selected': {
      fontWeight: '600'
    }
  }
};
