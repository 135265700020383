import React from 'react';
import {
    Typography,
    Box,
    makeStyles
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    root: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),

        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4),

        minHeight: 'calc(100vh - 64px)'
    },
}));

const WithoutAccess = (props) => {
    const {
        children,
        message
    } = props;
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <Box bgcolor={'white'} p={3} borderRadius={4}>
                <Box mb={2}>
                    <Typography variant={'h2'} align={'center'}>Доступ запрещен</Typography>
                </Box>

                <Typography variant={'h4'} align={'center'}>
                  {message || "У вас не достаточно прав для просмотра данного раздела"}
                </Typography>
            </Box>
        </div>
    )
}

export default WithoutAccess
