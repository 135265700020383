import palette from '../palette';

export default {
  root: {
    backgroundColor: palette.white,

    '&$selected': {
      backgroundColor: palette.background.default
    },
    '&$hover': {
      cursor: 'pointer',

      '&:hover': {
        backgroundColor: palette.background.default
      }
    },
    '&.MuiTableRow-list': {
      boxShadow: '0px 2px 8px rgba(142, 150, 168, 0.24)',
      '& td:first-child': {
        borderRadius: '4px 0 0 4px',
        overflow: 'hidden'
      },
      '& td:last-child': {
        borderRadius: '0 4px 4px 0',
        overflow: 'hidden'
      },

      '& .MuiTableCell-root': {
        padding: '5px 10px'
      }
    },
    '&.MuiTableRow-list-modal': {
      backgroundColor: 'rgba(194, 207, 224, 0.2)',

      '& td:first-child': {
        borderRadius: '4px 0 0 4px',
        overflow: 'hidden'
      },
      '& td:last-child': {
        borderRadius: '0 4px 4px 0',
        overflow: 'hidden'
      },
      '& .MuiTableCell-root': {
        padding: '5px'
      },
      '& .MuiTableCell-body': {
        color: 'black'
      }
    },
    '&.MuiTableRow-dark-hover': {
      '&:hover': {
        backgroundColor: '#ced9e6!important'
      }
    },
  }
};
