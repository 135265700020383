import {store} from "react-notifications-component";

const sendMessage = ({title, message, type}) => {
    store.addNotification({
        title: title || "Системное уведомление",
        message: message || "",
        type: type || "info",
        insert: 'top',
        container: 'bottom-left',
        dismiss: {
            duration: 3000,
            onScreen: false,
            pauseOnHover: true,
            delay: 0
        }
    });
}

export {
    sendMessage
}
