import axios from 'axios';
import varibles from '../varibles';
import {connect, useDispatch} from 'react-redux'
import {
  setUser
} from "../AppState";
import {clearLocalStorage, getLocalStorage} from "../helpers/localStorage";

const axiosAgent = async (type, url, data) => {
  let token = getLocalStorage('session_id');

  let params = {
    headers: {
      'Authorization': 'Bearer ' + token
    }
  }
  let tokenString = 'token=' + token;

  if ( url.indexOf('?') > -1 ){
    tokenString = '&' + tokenString
  }else{
    tokenString = '?' + tokenString
  }

  url = varibles.API_URL + url + tokenString;
  // url = `${window.location.protocol}//${window.location.hostname}:5555/api/v2${url}${tokenString}`;

  switch (type) {
    case 'get': {
      if (data) {
        params['data'] = data
      }

      return axios({
          method: 'get',
          url: url,
          ...params
        }
      ).catch((error) => {
        const { status } = error.response;
        if (status == 401) {
          window.location.href = "/";
          clearLocalStorage('session_id')
        }
        return Promise.reject(error)
      })
    }
    case 'post': {
      return axios({
        method: 'post',
        url: url,
        data: data,
        ...params
      }).catch((error) => {
        const { status } = error.response;
        if (status == 401) {
          window.location.href = "/";
          clearLocalStorage('session_id');
        }

        return Promise.reject(error)
      })
    }
    case 'put': {
      return axios({
        method: 'put',
        url: url,
        data: data,
        ...params
      }).catch((error) => {
        const { status } = error.response;
        if (status == 401) {
          window.location.href = "/";
          clearLocalStorage('session_id')
        }

        return Promise.reject(error)
      })
    }
    case 'delete': {
      return axios({
        method: 'delete',
        url: url,
        ...params
      }).catch((error) => {
        const { status } = error.response;
        if (status == 401) {
          window.location.href = "/";
          clearLocalStorage('session_id')
        }

        return Promise.reject(error)
      })
    }
  }
}



export default axiosAgent
