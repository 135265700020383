import { createMuiTheme } from '@material-ui/core';

import palette from './palette';
import typography from './typography';
import overrides from './overrides';

const theme = createMuiTheme({
  palette,
  typography,
  overrides,
  zIndex: {
    appBar: 1200,
    drawer: 1100
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1920,

      middlePC: 1399,
      smallPC: 1200,
    },
  },
});

theme.shadows[1] = '0px 2px 8px rgba(142, 150, 168, 0.24)';

export default theme;
